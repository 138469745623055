import React, { Component, useContext } from "react"
import ContextConsumer from "../services/context"
import { Link } from "gatsby"

import logo from '../../static/k7-logo.svg'
import djkicksLogo from '../../static/djkicks-logo.png'
import iconMenu from '../../static/menu.svg'
import iconX from '../../static/x.svg'
import iconSpotify from "../../static/icon_spotify.png"
import iconSoundcloud from "../../static/icon_soundcloud.png"
import iconInsta from "../../static/icon_insta.png" 
import iconTwitter from "../../static/icon_twitter.png"
import iconYoutube from "../../static/icon_youtube.png"
import iconFacebook from "../../static/icon_facebook.png"
import NewsletterForm from "../components/newsletterForm"
import iconBandcamp from "../../static/icon_bandcamp.png"

class MobileHeader extends Component {

  state = {
    menuActive: false,
  }

  closeMenu() {
    this.setState({
      menuActive: false,
    })
  }

  openMenu() {
    this.setState({
      menuActive: true,
    })
  }

  menuClick() {
    if( this.state.menuActive ) {
      this.closeMenu()
    } else {
      this.openMenu()
    }
  }

  componentDidMount() {
    window.buy.cart('.ochre-header-cart');
  }

  render () {

    let menuLinkCx = "border-b w-full p-2 uppercase mono block text-xs text-gray-700 hover:text-black bg-white"
    let menuHeight = 37
    let menuHeightStyle = {height: menuHeight + 'px', borderBottom: 'none'}
    let moreMenuTop, menuIcon;
    

    if ( this.state.menuActive ) {
      menuIcon = <img src={iconX} />
      moreMenuTop = menuHeight * 2 + 'px'
    } else {
      moreMenuTop = '-500px'
      menuIcon = <img src={iconMenu} />
    }
  
    const activeLinkCx = "text-black font-bold"
    const defaultLinkCx = "text-gray-700 hover:text-black hover:bg-gray-100 cursor-pointer"
    const djKicksActiveCx = "bg-k7green opacity-1"
    const djKicksDefaultCx = "hover:bg-gray-100 opacity-50 hover:opacity-100"
    
    const isActive = ({ isCurrent }) => {
      return isCurrent
        ? { className: `${menuLinkCx} ${activeLinkCx}`}
        : { className: `${menuLinkCx} ${defaultLinkCx}` }
    }

    const isDjkicksActive = ({ isCurrent }) => {
      return isCurrent
        ? { className: `${djKicksActiveCx} w-full border-r px-2 block flex items-center`}
        : { className: `${djKicksDefaultCx} w-full border-r px-2 block flex items-center opacity-50 hover:opacity-100` }
    }
    

    return (

      <ContextConsumer>
        {({ data, set }) => (
          <div>
            <div className="w-full" style={{height: menuHeight * 2 + 'px'}}></div>
            <header className="w-full flex fixed top-0 z-50 bg-white border-b">
              <Link to="/" onClick={() => this.closeMenu()} className="w-2/12 align-center border-t-0 border-r flex items-center bg-white">
                <div className="w-full text-center px-4">
                  <img className="inline-block" src={logo} width="80" />
                </div>
              </Link>

              <div className="flex-grow w-2/12 border-r">
                <Link 
                  className={`border-b-1`}
                  getProps={isActive} 
                  to="/" 
                  onClick={() => this.closeMenu()} 
                  style={{borderBottom: 'solid 1px #e2e8f0',}}
                >
                  Discography
                </Link>
                
                <div className="flex">
                  <Link getProps={isDjkicksActive} onClick={() => this.closeMenu()} to="/dj-kicks" style={menuHeightStyle}>
                    <img src={djkicksLogo} width="72" />
                  </Link>
                  <div 
                    className={`${menuLinkCx}`} 
                    style={menuHeightStyle}>
                    <div className="ochre-header-cart"></div>
                  </div>
                </div>
              </div>

              <div onClick={this.menuClick.bind(this)} className="cursor-pointer flex bg-white">
                <div className="w-full p-8 text-center">
                  {menuIcon}
                </div>
              </div>

              <div className="moreMenu fixed w-full border-t" style={{top: moreMenuTop}}>
                <Link 
                  getProps={isActive} 
                  onClick={() => this.closeMenu()} 
                  to="/store" 
                >
                  Store
                </Link>
                <Link 
                  getProps={isActive} 
                  onClick={() => this.closeMenu()} 
                  to="/news" 
                >
                  News
                </Link>
                <Link 
                  getProps={isActive} 
                  onClick={() => this.closeMenu()} to="/contact" 
                >
                  Contact
                </Link>
                <div className="bg-white py-2 border-b-1">
                  <a className="pr-3 inline-block align-middle opacity-75 hover:opacity-100" target="_blank" href="https://open.spotify.com/user/!k7" ><img className="block m-0" src={iconSpotify} width="30"/></a>
                  <a className="px-3 inline-block align-middle opacity-75 hover:opacity-100" target="_blank" href="https://k7records.bandcamp.com/"><img className="block m-0" src={iconBandcamp} width="30"/></a>
                  <a className="px-3 inline-block align-middle opacity-75 hover:opacity-100" target="_blank" href="https://soundcloud.com/k7-records"><img className="block m-0" src={iconSoundcloud} width="30"/></a>
                  <a className="px-3 inline-block align-middle opacity-75 hover:opacity-100" target="_blank" href="https://www.instagram.com/k7records/"><img className="block m-0" src={iconInsta} width="30"/></a>
                  <a className="px-3 inline-block align-middle opacity-75 hover:opacity-100" target="_blank" href="https://twitter.com/K7records"><img className="block m-0" src={iconTwitter} width="30"/></a>
                  <a className="px-3 inline-block align-middle opacity-75 hover:opacity-100" target="_blank" href="https://www.youtube.com/user/K7rec"><img className="block m-0" src={iconYoutube} width="30"/></a>
                  <a className="px-3 inline-block align-middle opacity-75 hover:opacity-100" target="_blank" href="https://www.facebook.com/K7Records"><img className="block m-0" src={iconFacebook} width="30"/></a>
                </div>
                <div className="p-4 oveflow-hidden bg-white border-b-1 border-black">
                  <NewsletterForm 
                    size="small"
                  />
                </div>
              </div>

            </header>
          </div>
        )}
      </ContextConsumer>

  
    )
  
  }

}


MobileHeader.contextType = ContextConsumer;
export default MobileHeader