import React, { Component, useContext } from "react"
import ContextConsumer from "../services/context"
import HeaderPlayerContainer from "./headerPlayer/headerPlayerContainer"
import { Link } from "gatsby"
import OnImagesLoaded  from 'react-on-images-loaded'
import logo from '../../static/k7-logo.svg'
import djkicksLogo from '../../static/djkicks-logo.svg'

import iconSpotify from "../../static/icon_spotify.png"
import iconSoundcloud from "../../static/icon_soundcloud.png"
import iconBandcamp from "../../static/icon_bandcamp.png"
import iconInsta from "../../static/icon_insta.png" 
import iconTwitter from "../../static/icon_twitter.png"
import iconYoutube from "../../static/icon_youtube.png"
import iconFacebook from "../../static/icon_facebook.png"


class HeaderDesktop extends Component {

  state = {
    imgLogoLoaded: false,
    imgDjkicksLoaded: false,
    socialMediaImagesLoaded: false,
  }

  componentDidMount() {
    window.buy.cart('.ochre-header-cart');
  }

  render () {
    let menuLinkCx = "w-full p-2 uppercase mono border-b block text-xs bg-white"
    let menuHeight = 37
    let menuHeightStyle = {height: menuHeight + 'px'}
    let headerInit = false;
    headerInit = this.state.imgLogoLoaded && this.state.imgDjkicksLoaded && this.state.socialMediaImagesLoaded ? true : false
    
    const activeLinkCx = "text-black font-bold"
    const defaultLinkCx = "text-gray-700 hover:text-black hover:bg-gray-100 cursor-pointer"
    const djKicksActiveCx = "bg-k7green"
    const djKicksDefaultCx = "hover:bg-gray-100"
    
    const isActive = ({ isCurrent }) => {
      return isCurrent
        ? { className: `${menuLinkCx} ${activeLinkCx}`}
        : { className: `${menuLinkCx} ${defaultLinkCx}` }
    }

    const isDjkicksActive = ({ isCurrent }) => {
      return isCurrent
        ? { className: `${djKicksActiveCx} w-full px-2 bg-k7green block flex items-center border-b`}
        : { className: `${djKicksDefaultCx} w-full px-2 bg-white block flex items-center border-b` }
    }
    
    // Animations
    let headerInitCx, socialInitCx, elFadeIn, elFadeIn1, elFadeIn2, elFadeIn3, elFadeIn4, elFadeIn5, elFadeIn6, elFadeIn7, elFadeIn8

    const headerInitCxDefault = {
      transformOrigin: 'top',
      transition: 'transform .5s',
      transitionProperty: 'transform',
      transitionDuration: '.5s',
      transitionDelay: '.3s'
    }

    const socialInitCxDefault = {
      transition: 'all .5s',
      transformOrigin: 'left',
      height: '37px',
      transitionDelay: '1.3s',
    }

    if (headerInit) {
      headerInitCx = {
        transform: 'scaleY(1)',
        opacity: 1,
        ...headerInitCxDefault
      }
      elFadeIn = {
        opacity: 1,
        transitionProperty: 'all',
        transitionDuration: '.2s', 
      }
      socialInitCx = {
        transform: 'scaleX(1)',
        ...socialInitCxDefault
      }
    } else {
      headerInitCx = {
        transform: 'scaleY(0)',
        opacity: 0, 
        ...headerInitCxDefault,
      }
      elFadeIn = {
        opacity: 0,
        transitionProperty: 'all',
        transitionDuration: '.2s', 
      }
      socialInitCx = {
        transform: 'scaleX(0)',
        ...socialInitCxDefault
      }
    }
  
    elFadeIn1 = {transitionDelay: '.8s', ...elFadeIn,}
    elFadeIn2 = {transitionDelay: '.95s', ...elFadeIn}
    elFadeIn3 = {'transitionDelay': '1.1s', ...elFadeIn, }
    elFadeIn4 = {transitionDelay: '1.25s', ...elFadeIn, }
    elFadeIn5 = {transitionDelay: '1.4s', ...elFadeIn, }
    elFadeIn6 = {transitionDelay: '1.55s', ...elFadeIn, }
    elFadeIn7 = {transitionDelay: '1.7s', ...elFadeIn, }
    elFadeIn8 = {transitionDelay: '1.7s', paddingTop: '3px', ...elFadeIn, }


    return (
      <ContextConsumer>
        {({ data, set }) => (
          <header className={`relative z-10 w-full flex p-6`} style={headerInitCx}>
            <Link to="/" onClick={() => this.updatePath("/")} className="w-2/12 align-center border flex items-center bg-white">
              <div className="w-full text-center">
                <OnImagesLoaded onLoaded={() => this.setState({ imgLogoLoaded: true })}>
                  <img className="inline-block" style={elFadeIn1} src={logo} width="80" />
                </OnImagesLoaded>
              </div>
            </Link>
            <div className="w-2/12 border-t border-r">
            <Link getProps={isActive} to="/">
              <span style={elFadeIn2}>Discography</span>
            </Link>
              <Link 
                to="/store" 
                getProps={isActive}
              >
                <span style={elFadeIn3}>Store</span>
                </Link>
              <Link to="/news" getProps={isActive}>
                <span style={elFadeIn4}>News</span>
              </Link>
            </div>


            <div className="w-2/12 border-t border-r">
              <Link 
                to="/dj-kicks" 
                getProps={isDjkicksActive}
                style={menuHeightStyle}
              >
                <OnImagesLoaded onLoaded={() => this.setState({ imgDjkicksLoaded: true })}>
                  <img style={elFadeIn5} src={djkicksLogo} width="72" />
                </OnImagesLoaded>
              </Link>
              <div className={`${menuLinkCx} ${defaultLinkCx}`} style={menuHeightStyle}>
                <div className={`ochre-header-cart relative ${defaultLinkCx}`} style={elFadeIn6}></div>
              </div>
              <Link to="/contact" getProps={isActive}>
                <span style={elFadeIn7}>Contact</span>
              </Link>
            </div>

            
            <div className="w-6/12">
              <HeaderPlayerContainer />
              <div className="bg-white border border-l-0 border-t-0 table-cell" style={socialInitCx}>
                <OnImagesLoaded onLoaded={() => this.setState({ socialMediaImagesLoaded: true })}>
                  <a className="px-3 inline-block align-middle opacity-75 hover:opacity-100" target="_blank" href="https://open.spotify.com/user/!k7" style={elFadeIn8}><img className="block m-0" src={iconSpotify} width="30"/></a>
                  <a className="px-3 inline-block align-middle opacity-75 hover:opacity-100" target="_blank" href="https://k7records.bandcamp.com/" style={elFadeIn8}><img className="block m-0" src={iconBandcamp} width="30"/></a>
                  <a className="px-3 inline-block align-middle opacity-75 hover:opacity-100" target="_blank" href="https://soundcloud.com/k7-records" style={elFadeIn8}><img className="block m-0" src={iconSoundcloud} width="30"/></a>
                  <a className="px-3 inline-block align-middle opacity-75 hover:opacity-100" target="_blank" href="https://www.instagram.com/k7records/" style={elFadeIn8}><img className="block m-0" src={iconInsta} width="30"/></a>
                  <a className="px-3 inline-block align-middle opacity-75 hover:opacity-100" target="_blank" href="https://twitter.com/K7records" style={elFadeIn8}><img className="block m-0" src={iconTwitter} width="30"/></a>
                  <a className="px-3 inline-block align-middle opacity-75 hover:opacity-100" target="_blank" href="https://www.youtube.com/user/K7rec" style={elFadeIn8}><img className="block m-0" src={iconYoutube} width="30"/></a>
                  <a className="px-3 inline-block align-middle opacity-75 hover:opacity-100" target="_blank" href="https://www.facebook.com/K7Records" style={elFadeIn8}><img className="block m-0" src={iconFacebook} width="30"/></a>
                </OnImagesLoaded>
              </div>
            </div>
          </header>
        )}
      </ContextConsumer>

  
    )
  
  }

}
HeaderDesktop.contextType = ContextConsumer;
export default HeaderDesktop

