import React, { Component} from "react"
import { getToken } from "../../utils/getToken"
import axios from "axios"
import ContextConsumer from "../../services/context"
import OnImagesLoaded  from 'react-on-images-loaded'

class HeaderPlayerContent extends Component {

  state = {
    featuredReleaseId: "",
    featuredPlayerTitle: "",
    featuredPlayerRelease: "",
    featuredPlayerArtist: "",
    featuredPlayerImg: "",
    featuredPlayerLoaded: false,
    musicPaused: true,
    playerInit: false,
    imgLoaded: false,
    featureTrackPlayed: false,
  }

  pauseMusic() {
    if (!this.state.musicPaused) {
      this.setState({
        musicPaused: true
      })
    }
  }

  playMusic() {
    const track = this.state.activeTrack
    if (this.state.musicPaused && !this.state.playerInit) {
      this.playerScriptLoaded(track);
    }

  }

  playFeaturedTracks() {
    this.context.data.loadTracksAndPlay( this.state.activeTrack )
    this.setState({
      featureTrackPlayed: true,
    })
  }

  fetchFeaturedReleaseTracks (featuredRelease, options) {
    const releaseId = featuredRelease.reference.id

    axios.get('https://api.ochre.io/v1/content/media-items?reference_type=release&type=audio&reference_id=' + releaseId , options)
    .then(response => {

      const featuredReleaseMediaItems = response.data.results  
      this.setState({
        featuredRelease: featuredRelease,
        fetchedFeaturedRelease: true,
        activeTrack: featuredReleaseMediaItems,
        featuredReleaseMediaItems: featuredReleaseMediaItems,
        fetchedFeaturedReleaseMediaItems: true,
        featuredPlayerLoaded: true,
        featuredPlayerTrack: featuredReleaseMediaItems[0],
      })
    }).catch(error => {
      console.log('ERROR', error)
      this.setState({ loading: false, error })
    })

  }

  fetchFeaturedReleaseContentItems (module, options) {
    const moduleId = module.node.itemId
    axios 
    .get('https://api.ochre.io/v1/cms/modules/' + moduleId + '/content-items?image_size=l', options)
    .then(response => {
      const featuredRelease = response.data.results[0]
      this.fetchFeaturedReleaseTracks(featuredRelease, options)
    })
    .catch(function (error) {
      console.log('ERROR CONTENT ITEM FETCH', error.reponse)
    })
  }

  fetchFeaturedRelease = (options) => {
    const allModules = this.props.indexModules.allModules
    allModules.edges.map( module => {
      if ( module.node.title == "Featured" ) {
        this.fetchFeaturedReleaseContentItems(module, options)
      }
    })
  }

  componentDidMount() {
    getToken().then(options => {
      this.fetchFeaturedRelease(options)
    }) 
  }

  render () {
    let menuLinkCx = "w-full p-2 uppercase mono border-b block text-xs text-gray-700 hover:text-black bg-white"
    let menuHeight = 37
    let menuHeightStyle = {height: menuHeight + 'px'}

    let featuredPlayerArtist,
    featuredPlayerTitle,
    featuredPlayerRelease,
    featuredPlayerImg
  
    if (this.state.featuredPlayerLoaded && this.state.featuredPlayerTrack) {
      const featuredPlayerTrack = this.state.featuredPlayerTrack
      featuredPlayerArtist = featuredPlayerTrack.references[0].resource.artist.name
      featuredPlayerTitle = featuredPlayerTrack.title
      featuredPlayerRelease = featuredPlayerTrack.references[0].resource.release.title
      featuredPlayerImg = featuredPlayerTrack.references[0].resource.release.images[2].url
    }
    
    // Animation
    const playerInit = this.state.imgLoaded ? true : false
    let PlayerStyle, elFadeIn, elFadeIn1, elFadeIn2, elFadeIn3

    elFadeIn = {
      transitionProperty: 'all',
      transitionDuration: '.2s', 
    }

    const playerStyleDefault = {
      transitionProperty: 'all',
      transitionDuration: '.3s', 
      transformOrigin: 'left'
    }

    if ( playerInit ) {
      PlayerStyle = {
        transform: 'scaleX(1)',
        ...playerStyleDefault
      }
      elFadeIn = {
        opacity: 1,
      }
    } else {
      PlayerStyle = {
        transform: 'scaleX(0)',
        ...playerStyleDefault
      }
      elFadeIn = {
        opacity: 0,
      }
    }

    elFadeIn1 = {transitionDelay: '1.5s', ...elFadeIn,}
    elFadeIn2 = {transitionDelay: '1.65s', ...elFadeIn}
    elFadeIn3 = {'transitionDelay': '1.8s', ...elFadeIn, }

    // Play button active state animation - removes play button once clicked
    const elAnimationStateDefault = {
      transformOrigin: 'left',
      transitionProperty: 'transform',
      transitionDuration: '.3s',
    }
    let elAnimationState = {
      transform: 'scaleX(1)',
      ...elAnimationStateDefault
    }
    
    if ( this.state.featureTrackPlayed ) {
      elAnimationState = {
        transform: 'scaleX(0)',
        ...elAnimationStateDefault
      }
    }

    return (
      <div className="w-full" style={PlayerStyle}>
        <div className="w-full flex">
          <div className="bg-white">
            <OnImagesLoaded onLoaded={() => this.setState({ imgLoaded: true })}>
              <img className="p-2 border border-l-0" width={menuHeight * 2} src={featuredPlayerImg}/>
            </OnImagesLoaded>
          </div>
          <div className="flex-grow">
            <div className="flex w-full">
              <div className={menuLinkCx + ' w-1/2 border-t'}>
                <span style={elFadeIn1}>{featuredPlayerTitle}</span>
              </div>
              <div className={menuLinkCx + ' w-1/2 border-l border-t border-r'}>
                <span style={elFadeIn2}>{featuredPlayerArtist}</span>
              </div>
            </div>
            <div className={menuLinkCx + ' w-full border-r'}>
               <span style={elFadeIn3}>{featuredPlayerRelease}</span>
            </div>
          </div>
          <div onClick={() => { this.playFeaturedTracks() }} style={elAnimationState} className="bg-white p-4 flex items-center border border-l-0 cursor-pointer hover:bg-gray-100">
            <div className="w-full align-center">►</div>
          </div>
        </div>
        <div></div>
      </div>
  
    )
  
  }

}

HeaderPlayerContent.contextType = ContextConsumer;
export default HeaderPlayerContent