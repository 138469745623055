// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-launch-js": () => import("./../src/templates/launch.js" /* webpackChunkName: "component---src-templates-launch-js" */),
  "component---src-templates-release-js": () => import("./../src/templates/release.js" /* webpackChunkName: "component---src-templates-release-js" */),
  "component---src-templates-dj-kicks-js": () => import("./../src/templates/djKicks.js" /* webpackChunkName: "component---src-templates-dj-kicks-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-about-css-modules-js": () => import("./../src/pages/about-css-modules.js" /* webpackChunkName: "component---src-pages-about-css-modules-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookiepolicy-js": () => import("./../src/pages/cookiepolicy.js" /* webpackChunkName: "component---src-pages-cookiepolicy-js" */),
  "component---src-pages-djkicks-history-js": () => import("./../src/pages/djkicks-history.js" /* webpackChunkName: "component---src-pages-djkicks-history-js" */),
  "component---src-pages-impressum-js": () => import("./../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-search-js": () => import("./../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-store-js": () => import("./../src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */)
}

