import React, { Component } from "react"
import { Link } from "gatsby"

class CookieFooter extends Component {
  state = {
    cookieApproved: false,
    cookieApprovalFinished: false,
  }

  checkForCookieApproval() {
    const cookieApproved = sessionStorage.getItem("cookiedApproved")
    if ( cookieApproved ) {
      this.approveCookie()
    } else {
      this.setState({
        cookieApproved: false,
        cookieApprovalFinished: true
      })     
    }
  }

  onButtonClick() {
    this.approveCookie()
  }

  trackScrolling() {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop
    if ( winScroll > 200 ) {
      this.approveCookie()
    } 
  }
  

  approveCookie() {
    sessionStorage.getItem("cookiedApproved", true)
    this.setState({
      cookieApproved: true,
      cookieApprovalFinished: true
    })
  }

  componentDidMount() {
    this.checkForCookieApproval()
    document.addEventListener('scroll', this.trackScrolling.bind(this))
  }

  render() {
    const styleDefault = {
      transform: 'translateY(200px)',
      transitionProperty: 'transform',
      transitionDuration: '.5s',
    }
    let style = {...styleDefault}

    if ( 
      this.state.cookieApproved && this.state.checkForCookieApproval || 
      !this.state.cookieApproved && !this.state.checkForCookieApproval
    ) {
      style = {
        ...styleDefault,
        transform: 'translateY(0px)',
      }
    }
    
    return (
      <>
        <div 
          className="z-50 uppercase flex fixed bottom-0 w-full bg-white border-t-1 border-black p-2 justify-between"
          style={style}
        >
          <div className="text-xs self-center mono">
            We use cookies to enhance your experience, by continuing to use this site you agree to our use of cookies — <Link to="/cookiepolicy" className="underline">More info</Link>
          </div> 
          <div>
            <div 
              className="bg-white border border-black rounded-full btn-padding-y px-4 hover:bg-k7green2 cursor-pointer"
              onClick={() => this.onButtonClick()}
            >
              OK
            </div>
          </div>
        </div>
      </>

    )

  }

}

export default CookieFooter
