import React, { Component, useContext } from "react"
import ContextConsumer from "../services/context"
import HeaderPlayerContainer from "./headerPlayer/headerPlayerContainer"
import HeaderDesktop from "./headerDesktop"
import HeaderMobile from "./headerMobile"

class Header extends Component {

  state = {
    winWidth: typeof window !== `undefined` ? window.innerWidth : null,
    windowInit: false,
  }

  updateWidth() {
    this.setState({
      winWidth: typeof window !== `undefined` ? window.innerWidth : null,
      windowInit: true
    })
  }

  componentDidMount() {
    window.buy.cart('.ochre-header-cart');
    this.updateWidth()
    window.addEventListener('resize', this.updateWidth.bind(this));
  }

  render () {
    let header
    let hideHeader = false

    const pathname = typeof window !== `undefined` ? window.location.pathname : ''
    if (pathname.includes('/launch/')) {
      hideHeader = true
    }

    if( this.state.windowInit && !hideHeader ) {
      if ( this.state.winWidth > 640 ) {
        header = <HeaderDesktop />
      } else {
        header = <HeaderMobile />
      }
    }

    return (<div>{header}</div>)
  
  }

}


Header.contextType = ContextConsumer;
export default Header