// Context.js
import React from "react"
import { getToken } from "../utils/getToken"
import axios from "axios"

const defaultContextValue = {

  data: {
    // set your initial data shape here
    authToken: '',
    authTokenExp: '', 
    authTokenReady: false,
    menuOpen: false,
    featuredRelease: {},
    fetchedFeaturedRelease: false,
    featuredReleaseMediaItems: [],
    fetchedFeaturedReleaseMediaItems: false,
    cartReady: false,
    musicPlaying: false,
    musicTracksLoaded: false,
    player: false,
  },
  set: () => {},
}

const { Provider, Consumer } = React.createContext(defaultContextValue)

class ContextProviderComponent extends React.Component {
  
  constructor() {
    super()

    const innerWidth = typeof window !== `undefined` ? window.innerWidth : null
    const innerHeight = typeof window !== `undefined` ? window.innerHeight : null
    this.setData = this.setData.bind(this)
    this.state = {
      ...defaultContextValue,
      set: this.setData,
      fetchToken: this.fetchToken,
      winWidth: innerWidth,
      winHeight: innerHeight,
    }
  }

  requestToken = () => {
    axios.get("/.netlify/functions/test").then( (token) => {
      return token.data
    }).catch(error => {
      console.log('error', error)
      return error
    });
  }

  fetchToken() {
    const token = sessionStorage.getItem("token");
    const exp = sessionStorage.getItem("exp");
    if (token) {
      const time = new Date().getTime();
      if (exp > time) {
          // Token is still valid
          return token
      } else {
          // Token expired
          return this.requestToken()
      }
    } else {
        return this.requestToken()
    }   
  }

  setData(newData) {
    this.setState(state => ({
      data: {
        ...state.data,
        ...newData,
      },
    }))
  }

  initCart() {
    const cartScript = document.createElement("script");
    cartScript.src = "https://k7-records.k7store.com/cart/embed/library";
    cartScript.async = true;
    cartScript.onload = () => this.setState({
      cartReady: true
    })
    document.body.appendChild(cartScript);
  }

  updateDimensions() {
    const innerWidth = typeof window !== `undefined` ? window.innerWidth : null
    const innerHeight = typeof window !== `undefined` ? window.innerHeight : null

    this.setState(state => ({
      winWidth: innerWidth, 
      winHeight: innerHeight,
    }))
  }

  loadTracksAndPlay(tracks) {
    console.log('load tracks and play', this.state.data.player)
    this.state.data.player.play(tracks)
    this.setData({
      musicPlaying: true,
      musicTracksLoaded: true,
    })
  }

  playMusic() {
    const player = this.state.data.player
    player.play()
    this.setData({
      musicPlaying: true,
    })
  }

  pauseMusic() {
    const player = this.state.data.player
    if ( player ) {
      player.pause()
      this.setData({
        musicOnPause: false,
      })
    }
  }

  initPlayer() {
    const player = window.Player.init('.ochre-player');
    console.log('init player', player)
    this.setData({
      playMusic: this.playMusic.bind(this),
      loadTracksAndPlay: this.loadTracksAndPlay.bind(this),
      pauseMusic: this.pauseMusic.bind(this),
      player: player,
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions.bind(this));
    
    // Tring to fetch token and pass through state into app
    getToken().then(data => {
      this.setData({
        authToken: data,
      })
    })

    this.initPlayer()
  }

  
  render() {
    return (
      <Provider value={this.state}>
        {this.props.children}
        <div>
          <div className="ochre-player"></div>
        </div>
      </Provider>
    )
  }
}

export { Consumer as default, ContextProviderComponent }
