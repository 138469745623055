import React, { useContext } from "react"
import { Link } from "gatsby"
import logo from '../../static/k7-logo.svg'
import NewsletterForm from './newsletterForm'

const Footer = () => {
  var d = new Date();
  var y = d.getFullYear();
  let menuLinkCx = "w-full p-2 uppercase mono border border-l-0 block text-xs text-gray-700 hover:text-black hover:bg-gray-100 bg-white"

  return (
    <footer className="p-6 bg-white">

      <div className="border border-b-0 w-full py-8 flex md:hidden lg:hidden xl:hidden items-center justify-center">
        <div>
          <div className="mono text-xs uppercase black">
            Sign up to K7 release updates
          </div>
          <NewsletterForm 
            size="small"
          />
        </div>
      </div>

      <div className="flex">

        <Link to="/" className="w-3/8 md:w-1/8 lg:w-1/8 xl:w-1/8 order-1 justify-center border overflow-hidden flex items-center text-center">
          <img className="inline-block" src={logo} width="80" />
        </Link>

        <div className="hidden md:flex lg:flex xl:flex md:w-2/8 lg:w-2/8 xl:w-2/8 order-3 md:order-2 lg:order-2 xl:order-3 border border-l-0 mono text-center align-middle text-xs text-center items-center">
          <div className="w-full text-center">{`© !K7 Label Group ${y}`}</div>
        </div>

        <div className="w-5/8 md:w-2/8 lg:w-2/8 xl:w-2/8 order-2 md:order-3 lg:order-3 xl:order-3">
          <Link to="/privacy-policy" className={`${menuLinkCx} border-t-1`}>Privacy Policy</Link>
          <Link to="/impressum" className={`${menuLinkCx} border-t-0`}>Privacy Impressum</Link>
          <Link to="/cookiepolicy" className={`${menuLinkCx} border-t-0`}>Cookie Policy</Link>
          <div className="mono p-2 border border-t-0 border-l-0 text-left align-middle text-xs block md:hidden lg:hidden xl:hidden w-full">{`© !K7 Label Group ${y}`}</div>
        </div>
        
        <div className="w-full hidden md:flex lg:flex xl:flex order-4 md:w-3/8 lg:w-3/8 xl:w-3/8 items-center justify-center">
          <div>
            <div className="mono text-xs uppercase black">
              Sign up to K7 release updates
            </div>
            <NewsletterForm 
              size="small"
            />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer