import React from "react"
import { ContextProviderComponent } from "../services/context"
import Header from '../components/header'
import Footer from '../components/footer'
import CookieFooter from '../components/cookieFooter'

export default ({ children }) => {
  return (
    <ContextProviderComponent>
      <Header/>
      {children}
      <Footer />
      <CookieFooter />
    </ContextProviderComponent>
  )
}
