var axios = require("axios");


export const getToken = () => {

  const token = sessionStorage.getItem("token");
  const exp = sessionStorage.getItem("exp");
  const time = new Date().getTime()/1000;
  
  const createOptions = function(token) {
    const options = {
      headers: { 'Authorization': 'Bearer ' + token }
    }
    return options
  }

  if (token && exp > time) {
    const options = createOptions(token)
    return Promise.resolve(options)
  } else {

    return axios.get("https://www.k7records.com/jwt.php").then( (token) => {
      const accessToken = token.data.access_token
      const expiresIn = token.data.expires_in
      const exp = JSON.stringify( (new Date().getTime()/1000)+ expiresIn);
      sessionStorage.setItem("exp", exp);
      sessionStorage.setItem("token", accessToken);
      const options = createOptions(accessToken)
      return options
    }).catch(error => {
      console.log('error', error)
    });

  }

}

