import React, { Component } from "react"
import axios from "axios"

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

class ContactForm extends Component {
  state = {
    email: '',
    loading: false,
    formSuccess: false,
    consent: false
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }
  
  handleConsentChange = event => {
    if ( this.state.consent == true ) {
      this.setState({consent: false})
    } else {
     this.setState({consent: true}) 
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    
    if ( this.state.consent == true ) {
      this.setState({loading: true})
      const data = new FormData(event.target);
      fetch('https://k7837.activehosted.com/proc.php', {
        method: 'POST',
        body: data,
        mode: 'no-cors',
      })
      .then(response => {
        this.setState({
          formSuccess: true,
          loading: false
        })

      })
      .catch(err => {
        console.log('err', err);
        alert(err);
      });      
    }  
    
  }

  render() {
    let inputCx, buttonCx, successMessage, loading, consentMessage
    if ( this.props.size === "small") {
      inputCx = "w-full text-xs md:w-4/12 lg:w-4/12 xl:w-4/12 border bg-none border-black hover:border-gray-400 uppercase mono p-2 mb-1"
      buttonCx = "border text-xs border-black bg-k7green hover:bg-k7green2 mono-bold py-2 px-3 md:ml-2 lg:ml-2 xl:ml-2 uppercase" 
    } else {
      inputCx = "w-full md:w-4/12 lg:w-4/12 xl:w-4/12 border border-gray-400 hover:border-black uppercase mono p-3 text-sm mb-1"
      buttonCx = "border border-black bg-k7green hover:bg-k7green2 mono-bold py-3 px-8 md:ml-2 lg:ml-2 xl:ml-2 uppercase" 
    }

    if ( this.state.formSuccess ) {
      successMessage = <div className="inline-block px-1 mono text-xs uppercase bg-k7green">You're all signed up</div>
    }

    if ( this.state.loading ) {
      loading = <div className="inline-block px-1 mono text-xs uppercase bg-gray-400">Loading...</div>
    }
    
    consentMessage = <div className="inline-block px-1 mono text-xs uppercase">I agree to be contacted by email</div>
    
    return (
      <form 
        name="signup"
        method="POST"
        onSubmit={this.handleSubmit}
      >
        <div>
           <input type="hidden" name="u" value="3" />
          <input type="hidden" name="f" value="3" />
          <input type="hidden" name="s" />
          <input type="hidden" name="c" value="0" />
          <input type="hidden" name="m" value="0" />
          <input type="hidden" name="act" value="sub" />
          <input type="hidden" name="v" value="2" />
          <input 
            className={inputCx}
            type="text" 
            name="email" 
            placeHolder="Your email address"
            value={this.state.emailValue}
            onChange={this.handleInputChange}
            style={{
              minWidth: '170px',
            }}
          />
          <button 
            className={buttonCx}
            type="submit"
          >
            SIGN UP
          </button>

          <div className="newsletterConsent">
            <input
              type="checkbox"
              name="consent"
              className="consentCheckbox inline-block" 
              onChange={this.handleConsentChange}
            />
            {consentMessage}
          </div>

          <div>
            {successMessage}
            {loading}
          </div>
        </div>
      </form>
    )

  }

}

export default ContactForm


