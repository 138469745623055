import React, { Component} from "react"
import HeaderPlayerContent from "./headerPlayerContent"
import { StaticQuery, graphql } from "gatsby"

const HeaderPlayerContainer = () => (
  <StaticQuery
    query={graphql`
      {
        allModules(filter: {view: {slug: {eq: "discography-website"}}}) {
          edges {
            node {
              type
              title
              id
              itemId
            }
          }
        }
      }
    `}
    render={data => <HeaderPlayerContent indexModules={data}/>
    }
  ></StaticQuery>
)

export default HeaderPlayerContainer

